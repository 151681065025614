import React from 'react'
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col, Hidden } from 'react-grid-system'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Hero from '../components/rows/hero'
import Intro from '../components/rows/intro'
import ImgSection from '../components/rows/imgsection'
import 'react-lazy-load-image-component/src/effects/blur.css'

import Banner from '../images/img/hero/news-hero.jpg'
import Slide1 from '../images/img/michigan-gallery/michigan-1.jpg'
import Slide2 from '../images/img/michigan-gallery/michigan-2.jpg'
import Slide3 from '../images/img/michigan-gallery/michigan-3.jpg'
import Slide4 from '../images/img/michigan-gallery/michigan-4.jpg'
import Slide5 from '../images/img/michigan-gallery/michigan-5.jpg'
import Slide6 from '../images/img/michigan-gallery/michigan-6.jpg'
import Slide7 from '../images/img/michigan-gallery/michigan-7.jpg'

import Article from '../../static/article.pdf'

const slideShow = [
  {
    image: Slide1,
  },
  {
    image: Slide2,
  },
  {
    image: Slide3,
  },
  {
    image: Slide4,
  },
  {
    image: Slide5,
  },
  {
    image: Slide6,
  },
  {
    image: Slide7,
  }
]

export default function IndustryNews({data}) { // <== Make sure data is sent to the function
  
    return (
        <Layout>
        <Seo title="Industry News" />
        <Hero 
            title="News"
            banner={Banner}
        />
        {data.allStrapiIndustryNews.edges.map(({ node }) => ( // <== Map out the data from the node.
            <> 
                <Intro key={node.industrynews_intro.id}
                    title={node.industrynews_intro.title}
                    subtitle={node.industrynews_intro.subtitle}
                    description={node.industrynews_intro.description}
                />
                <div className="bg-green-300 py-56 shadow-xl">
                  <Container className="news">

                    {/* Blog Row */}
                    <Row className="flex mb-20">
                      <Hidden xs sm md>
                        <Col md={1}></Col>
                      </Hidden>
                      <Col md={2}>
                        <LazyLoadImage 
                          alt="Blog Post Thumbnail"
                          src={node.industrynews_img1.image.localFile.publicURL}
                          effect="blur"
                        />
                      </Col>
                      <Col md={8}>
                          <p className="text-white md:mt-0">{node.industrynews_img1.subtitle}</p>
                          <h3 className="text-orange-300">{node.industrynews_img1.title}</h3>
                          <p className="text-white">{node.industrynews_img1.description}</p>
                          <a href={Article} target="_blank" className="underline pt-10 inline-flex text-white hover:text-orange-300" rel="noopener noreferrer">Download PDF</a>
                      </Col>
                      <Col md={1}></Col>
                    </Row>

                    {/* Blog Row */}
                    <Row className="flex mb-20">
                      <Hidden xs sm md>
                        <Col md={1}></Col>
                      </Hidden>
                      <Col md={2}>
                        <LazyLoadImage 
                          alt="Blog Post Thumbnail"                        
                          src={node.industrynews_img2.image.localFile.publicURL}
                          effect="blur"
                        />
                      </Col>
                      <Col md={8}>
                          <p className="text-white md:mt-0">{node.industrynews_img2.subtitle}</p>
                          <h3 className="text-orange-300">{node.industrynews_img2.title}</h3>
                          <p className="text-white">{node.industrynews_img2.description}</p>
                          <Link to='/blog/canadian-company-partners-with-usda' className="underline pt-10 inline-flex text-white hover:text-orange-300">Read Full Story</Link>
                      </Col>
                      <Col md={1}></Col>
                    </Row>

                    {/* Blog Row */}
                    <Row className="flex">
                      <Hidden xs sm md>
                        <Col md={1}></Col>
                      </Hidden>
                      <Col md={2}>
                        <LazyLoadImage 
                          alt="Blog Post Thumbnail"
                          src={node.industrynews_img3.image.localFile.publicURL}
                        />
                      </Col>
                      <Col md={8}>
                          <p className="text-white md:mt-0">{node.industrynews_img3.subtitle}</p>
                          <h3 className="text-orange-300">{node.industrynews_img3.title}</h3>
                          <p className="text-white">{node.industrynews_img3.description}</p>
                          <Link to="/blog/american-wood-science-study" className="underline pt-10 inline-flex text-white hover:text-orange-300">Read Full Story</Link>
                      </Col>
                      <Col md={1}></Col>
                    </Row>

                  </Container>
                </div>

                <ImgSection key={node.industrynews_img4.id}
                    white
                    slideshow={slideShow}
                    subtitle={node.industrynews_img4.subtitle}
                    title={node.industrynews_img4.title}
                    description={node.industrynews_img4.description}      
                    image={node.industrynews_img4.image.localFile.publicURL}
                />
            </>
        ))}            
        </Layout>    
    )
}


export const query = graphql`
query IndustryNews {
  allStrapiIndustryNews {
    edges {
      node {
        industrynews_intro {
          id
          subtitle
          title
          description
        }
        industrynews_img1 {
          id
          subtitle
          title
          description
          button_title
          button_link
          image {
            localFile {
              publicURL
            }
          }
        }
        industrynews_img2 {
          id
          subtitle
          title
          description
          button_title
          button_link
          image {
            localFile {
              publicURL
            }
          }
        }
        industrynews_img3 {
          id
          subtitle
          title
          description
          button_link
          button_title
          image {
            localFile {
              publicURL
            }
          }
        }
        industrynews_img4 {
          id
          subtitle
          title
          description
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
}
`